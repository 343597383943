import React from 'react';
import { node, string, number, bool } from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

const propTypes = {
  children: node.isRequired,
  className: string,
  loop: bool.isRequired,
  autoplay: bool.isRequired,
  spaceBetween: number.isRequired,
};

const defaultProps = {
  className: undefined,
};

const Carousel = ({ children, className, loop, autoplay, spaceBetween }) => {
  const slides = React.Children.toArray(children);

  return (
    <Swiper
      className={className}
      modules={[Navigation, Pagination, Autoplay]}
      loop={loop}
      autoplay={autoplay}
      slidesPerView={1}
      spaceBetween={spaceBetween}
      navigation
      pagination={{ clickable: true }}
      breakpoints={{
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      }}
    >
      {slides.map((child) => {
        return <SwiperSlide key={child.key}>{child}</SwiperSlide>;
      })}
    </Swiper>
  );
};

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

export default Carousel;
