import React from 'react';
import { string } from 'prop-types';

import IconButton from '../IconButton/IconButton';

import BlueWaveOne from '../../images/blue-wave-1.svg';
import BlueWaveTwo from '../../images/blue-wave-2.svg';

const propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  label: string.isRequired,
  uri: string.isRequired,
  image: string.isRequired,
};

const Banner = ({ title, text, label, uri, image }) => {
  return (
    <div className="c-banner position-relative w-100 d-flex align-items-center gap-5 rounded-xs bg-secondary">
      <img
        src={image}
        alt=""
        className="position-absolute start-0 bottom-0 z-10 d-none d-md-block ps-4"
      />
      <div className="position-relative z-10">
        <h4 className="fw-bold text-white mb-2">{title}</h4>
        <p className="text-white">{text}</p>
        <IconButton className="c-icon-button__big shadow-none" href={uri}>
          {label}
        </IconButton>
      </div>
      <img
        src={BlueWaveOne}
        alt=""
        className="c-banner-top-wave position-absolute w-100 start-0"
      />
      <img
        src={BlueWaveTwo}
        alt=""
        className="c-banner-bottom-wave position-absolute w-100 start-0"
      />
    </div>
  );
};

Banner.propTypes = propTypes;

export default Banner;
