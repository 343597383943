import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const ImageCard = ({ children, className, ...props }) => {
  const classes = cx('c-image-card d-grid', className);

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

ImageCard.propTypes = propTypes;
ImageCard.defaultProps = defaultProps;

export default ImageCard;
